import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import {Provider} from 'react-redux';
import {I18nProvider} from './i18n';
import * as zhCN from "./i18n/zh-CN";
import * as en from './i18n/en-US';
import 'zent/css/index.css'

ReactDOM.render(
    <Provider store={store}>
        <I18nProvider value={true ? zhCN : en}>
            <App/>
        </I18nProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

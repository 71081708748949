import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { BlockLoading } from 'zent'

import WebFooter from "../component/WebFooter";
import WebHeader from "../component/WebHeader";
const Main = lazy(() => import("../component/Main"));
const Information = lazy(() => import("../component/Information"));
const SearchResult = lazy(() => import("../component/SearchResult"));
const CompanyResume = lazy(() => import("../component/company/CompanyResume"));
const Platform = lazy(() => import("../component/company/Platform"));

const Loading = () => {
  return (
    <div className="page-loading">
        <BlockLoading loading icon="circle" iconSize={64} iconText="加载中" />
  </div>)
};

const BasicRoute = () => (
  <BrowserRouter basename="/web">
    <WebHeader />
    {/* <Loading/> */}
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/main" component={Main} />
        <Route path="/info" component={Information} />
        <Route strict path="/search" component={SearchResult} />
        <Route exact path="/contact-us" component={CompanyResume} />
        <Route exact path="/join-us" component={Platform} />
        <Redirect strict path="*" to="/main" />
      </Switch>
    </Suspense>
    <WebFooter />
  </BrowserRouter>
);

export default BasicRoute;
